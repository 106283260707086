<script>
  import IconAngleDown from '@/images/icons/chevron-down.svg'

  /** Accordion Base Component new. */

  export default {
    name: 'BaseAccordion',
    components: {
      IconAngleDown,
    },
    props: {
      index: {
        type: Number,
      },
      nested: {
        type: Boolean,
        default: false
      },
      dataId:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        isOpen: false,
        openAccordeon: false
      }
    },
    watch:{
      isOpened(newVal) {
        if (newVal) {
          this.isOpen = true
        }else {
          this.isOpen = false
        }
      }
    },
    mounted() {
      const storage = localStorage.getItem('filters')
      if(storage) {
        const parsed = JSON.parse(storage)
        const isNotEmpty = Object.values(parsed).every(arr => {
          return arr.length === 0
        })
        if(!isNotEmpty) {
          if(this.dataId === 'materials') {
            if(parsed.primaryMaterials.length !== 0 || parsed.secondaryMaterials.length !== 0) {
              this.openAccordeon = true
              this.toggleIcon()
            }
          }else {
            if(parsed[this.dataId] && parsed[this.dataId].length !== 0) {
              this.openAccordeon = true
              this.toggleIcon()
            }
          }
        }
      }
    },
    methods: {
      toggleIcon() {
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<template>
  <details :id="index" class="border-gray-300" :open="openAccordeon">
      <summary class="w-full text-left outline-0 bg-black text-white px-5 flex">
        <span class="flex justify-between items-center w-full accordeon--title font-unica77 font-medium tracking-widest" @click="toggleIcon">
          <span class="max-w-4/5 md:max-w-full">
            <slot name="heading"></slot>
          </span>
          <IconAngleDown
            width="15px"
            height="15px"
            class="transform origin-center transition duration-300 icon-down fill-white"
            :class="`${ isOpen ? 'rotate-180' : '' }`"
            fill="#ffffff"
          />
        </span>
      </summary>
      <div :class="`accordion-content w-full px-4 border-l border-r border-gray-300 bg-white ${nested ? 'border-none' : 'py-4 pb-2'}`">
        <slot name="content"></slot>
      </div>
    </details>
</template>

<style scoped>
  summary::-webkit-details-marker {
    display: none;
  }

  .accordeon--title {
    padding: 18px 0;
  }

  summary {
    list-style: none;
    cursor: pointer;
    outline: none;
    /** set fallback focus styles */
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  details .filterRightSelect {
    width: auto;
    margin-left: 0;
  }

  .accordion-content {
    ul {
      @apply mt-0;
    }
  }
</style>
