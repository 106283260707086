<script>
  import { createNamespacedHelpers } from 'vuex'
  import { debounce } from 'lodash'
  import ImageLogo from '@/images/logo.svg'
  import BaseLink from '@/components/BaseLink'
  import BaseError from '@/components/BaseError'
  import IconPlus from '@/images/icons/plus.svg'
  import IconMinus from '@/images/icons/minus.svg'
  import IconNewsletters from '@/images/icons/newsletters-arrow.svg'
  import MakeAnAppointmentPopup from '@/components/SitePopupForm'
  import api from '@/api'
  import recaptchaService from '@/services/recaptcha'

  import IconFacebook from '@/images/icons/socials/facebook.svg'
  import IconTwitter from '@/images/icons/socials/twitter.svg'
  import IconInstagram from '@/images/icons/socials/instagram.svg'
  import IconPinterest from '@/images/icons/socials/pinterest.svg'
  import IconLinkedIn from '@/images/icons/socials/linkedin.svg'
  import IconYoutube from '@/images/icons/socials/youtube.svg'
  import IconClose from '@/images/icons/close.svg'

  const { mapState } = createNamespacedHelpers('global')
  const { mapState: productState } = createNamespacedHelpers('products')

  /** Global footer component */

  export default {
    name: 'GlobalFooter',

    components: {
      BaseLink,
      BaseError,
      ImageLogo,
      IconPlus,
      IconMinus,
      IconNewsletters,
      IconFacebook,
      IconTwitter,
      IconInstagram,
      IconPinterest,
      IconLinkedIn,
      IconYoutube,
      IconClose,
      MakeAnAppointmentPopup,
    },
    props: {
      /** Footer columns as matrix fields */
      columns: {
        type: Object,
        required: true,
      },
      appform: {
        type: Object,
        required: true,
      },
      /** Form hash and honeypot info */
      form: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        formFields: {
          email: '',
        },
        isVerify: false,
        mobileMenuIsOpen: false,
        mobileActiveNavItemIds: [],
        errorMsg: '',
        success: null,
        isSending: false,
        isSent: false,
        isValidEmail: null,
        noticeHeight: 0,
        submitCount: 0,
        popupInfoHeight: 0,
        goesUp: 0,
      }
    },
    computed: {
      ...mapState(['social']),
      ...productState(['selectedOptionInfo']),
      emailError() {
        return !this.isValidEmail && this.isValidEmail !== null
      },
      privacyPolicy() {
        return this.columns.legalNavigation.find((item) => {
          return item.title.toLowerCase() === 'privacy policy'
        })
      },
    },
    async mounted() {
      await recaptchaService.loadRecaptcha()
      let noticeElement = document.querySelector('#notice')
      if (noticeElement === null) {
        this.goesUp = 50
      }
      const closePopup = document.querySelector('.appointmentFormPopup__close')
      const popup = document.querySelector('.appointmentFormPopup')
      const overlay = document.querySelector('.mainOverlay')
      const body = document.querySelector('body')
      closePopup.addEventListener('click', function () {
        overlay.style.display = 'none'
        popup.style.display = 'none'
        body.classList.remove('overflow-y-hidden')
      })
      overlay.addEventListener('click', function () {
        overlay.style.display = 'none'
        popup.style.display = 'none'
        body.classList.remove('overflow-y-hidden')
      })
      const notice = document.getElementById('notice')
      if (notice) {
        this.noticeHeight = notice.offsetHeight
      }
    },
    methods: {
      /** Returns whether the given nav item's subnav is open on mobile. */
      mobileSubnavIsOpen(navItem) {
        return this.mobileActiveNavItemIds.some((activeNavItemId) => activeNavItemId === navItem.id)
      },
      /** Opens a nav item's subnav on mobile. */
      mobileOpenSubnav(navItem) {
        this.mobileActiveNavItemIds = [
          ...this.mobileActiveNavItemIds.filter(
            (activeNavItemId) => activeNavItemId !== navItem.id
          ),
          navItem.id,
        ]
      },
      /** Closes a nav item's subnav on mobile. */
      mobileCloseSubnav(navItem) {
        this.mobileActiveNavItemIds = this.mobileActiveNavItemIds.filter(
          (activeNavItemId) => activeNavItemId !== navItem.id
        )
      },
      /** Opens or closes a nav item's subnav on mobile. */
      mobileToggleSubnav(navItem) {
        if (this.mobileSubnavIsOpen(navItem)) {
          this.mobileCloseSubnav(navItem)
        } else {
          this.mobileOpenSubnav(navItem)
        }
      },
      /** Method to handle for submission to freeform via api */
      async newsletterSubscribe() {
        try {
          this.isSending = true

          // Get reCAPTCHA token
          const recaptchaToken = await recaptchaService.executeRecaptcha('newsletter_signup')

          api.post('freeform/submit', {
            formHash: this.form.hash,
            email: this.formFields.email,
            [this.form.honeypot.name]: this.form.honeypot.hash,
            'g-recaptcha-response': recaptchaToken
          })
          .then((response) => {
            if (!response.data.success) {
              this.submitCount++
              this.success = false
              this.isSending = false
              if (this.submitCount < 2) {
                this.newsletterSubscribe()
              } else {
                this.errorMsg = response.data.errors[0] || response.data.formErrors[0]
              }
            } else if (response.status !== 200) {
              this.errorMsg = 'Oops! Something went wrong. Please try again.'
              this.success = false
            } else {
              this.isSending = false
              this.isSent = true
              return api.post('/klaviyoconnect/api/track', {
                event: {
                  name: 'Newsletter Signup',
                },
                list: 'RRrbrf',
                email: this.formFields.email,
              })
            }
          })
        } catch (error) {
          this.errorMsg = 'An error occurred. Please try again later.'
          this.success = false
          this.isSending = false
        }
      },
      validateEmail: debounce(function (email) {
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        this.isValidEmail = reg.test(email)
      }, 1000),
    },
  }
</script>

<template>
  <div>
    <footer :class="`global-footer static text-white bg-black`">
      <div
        class="w-full mx-auto lg:max-w-screen-2xl px-4 md:px-6 flex flex-col lg:flex-row lg:justify-between pt-14 pb-0 lg:pt-20 md:pb-15"
      >
        <div class="flex flex-col mb-16 lg:mb-0 footer-wrapper">
          <div
            class="w-full relative md:border-t-0 border-gray-600 | md:px-0 md:py-0 md:border-b-0 xl:mt-0 || js-footer-newsletter-form"
          >
            <form v-if="!isSent" id="newsletter-signup-form" @submit.prevent="newsletterSubscribe">
              <div class="w-full mt-0">
                <div class="space-y-3 w-full relative">
                  <label class="block mb-2 font-semibold uppercase tracking-wide">Newsletter</label>
                  <input
                    v-model="formFields.email"
                    type="text"
                    placeholder="Your Email"
                    class="border-b border-gray-500 w-full px-0 h-7 focus:outline-none placeholder-gray-500 font-normal bg-transparent pr-8"
                    @input="validateEmail(formFields.email)"
                  />
                  <button
                    ref="newsletterForm"
                    class="absolute top-9 right-0 w-5 h-2"
                    type="submit"
                    tabindex="1"
                    :disabled="emailError || isSending || formFields.email === ''"
                  >
                    <IconNewsletters w-full />
                  </button>
                </div>
                <BaseError v-if="emailError" error="Please enter a valid email address" />
                <BaseError v-else-if="errorMsg" :error="errorMsg" class="text-center" />
              </div>
            </form>
            <p v-if="isSent" class="w-full text-center"> Thanks for signing up! </p>
          </div>
          <ul class="w-full flex flex-row space-x-4 mt-6 mb-16">
            <li v-if="social.instagram">
              <a
                :href="social.instagram"
                target="_blank"
                aria-label="Instagram"
                class="block w-5 h-5 overflow-hidden rounded-full"
              >
                <IconInstagram w-full h-full />
              </a>
            </li>
            <li v-if="social.facebook">
              <a
                :href="social.facebook"
                target="_blank"
                aria-label="Facebook"
                class="block w-5 h-5 overflow-hidden rounded-full"
              >
                <IconFacebook w-full h-full />
              </a>
            </li>
            <li v-if="social.linkedin">
              <a
                :href="social.linkedin"
                target="_blank"
                aria-label="Linkedin"
                class="block w-5 h-5 overflow-hidden rounded-full"
              >
                <IconLinkedIn w-full h-full />
              </a>
            </li>
            <li v-if="social.pinterest">
              <a
                :href="social.pinterest"
                target="_blank"
                aria-label="Pinterest"
                class="block w-5 h-5 overflow-hidden rounded-full"
              >
                <IconPinterest w-full h-full />
              </a>
            </li>
            <li v-if="social.youtube">
              <a
                :href="social.youtube"
                target="_blank"
                aria-label="Youtube"
                class="block w-6 h-6 overflow-hidden"
              >
                <IconYoutube class="w-full h-full" />
              </a>
            </li>
            <!-- <li v-if="social.twitter">
              <a
                :href="social.twitter"
                target="_blank"
                aria-label="Twitter"
                class="block w-5 h-5 overflow-hidden rounded-full"
              >
                <IconTwitter w-full h-full />
              </a>
            </li> -->
          </ul>
          <dl class="max-w-full space-y-2 | xs:space-y-1 | md:py-0">
            <dd
              class="leading-5 space-x-0 space-y-4 md:space-y-0 md:space-x-16 flex flex-col md:flex-row lg:space-x-0 lg:justify-between pr-6"
            >
              <div
                class="max-w-1/2 mb-4 text-white | md:max-w-none text-gray || footer-address"
                v-html="columns.footerInfo.address"
              />
              <div
                class="max-w-1/2 mb-4 text-white | md:max-w-none text-gray || footer-address"
                v-html="columns.footerInfo.address2"
              />
            </dd>
          </dl>
        </div>

        <div class="w-full lg:w-7/12 xl:w-6/12 max-w-4xl space-x-0 md:space-y-12">
          <!-- Desktop navigation -->
          <div class="hidden md:flex md:flex-row justify-between">
            <ul v-for="navItem in columns.footerNavigation" :key="navItem.id" class="space-y-2">
              <li class="font-semibold uppercase tracking-wide mb-4">{{ navItem.title }}</li>
              <li
                v-for="subNavItem in navItem.children"
                :key="subNavItem.id"
                class="text-white-600"
              >
                <a :href="subNavItem.url" class="hover:text-yellows"> {{ subNavItem.title }}</a>
              </li>
            </ul>
          </div>

          <!-- Mobile navigation -->
          <ul class="w-full md:hidden flex flex-col border-t border-gray-300">
            <li v-for="navItem in columns.footerNavigation" :key="navItem.id">
              <div class="flex h-12 border-b border-gray-600">
                <button
                  type="button"
                  class="relative flex justify-between items-center min-w-0 flex-1 py-1 font-bold"
                  @click="mobileToggleSubnav(navItem)"
                >
                  <span class="truncate">{{ navItem.title }}</span>
                  <span
                    class="left-0 ml-1 inset-y-0 flex justify-center items-center w-8 invert-color"
                  >
                    <IconMinus v-if="mobileSubnavIsOpen(navItem)" class="w-3 h-3" />
                    <IconPlus v-else class="w-3 h-3" />
                  </span>
                </button>
              </div>
              <ul
                v-if="navItem.children.length > 0 && mobileSubnavIsOpen(navItem)"
                class="border-b border-gray-300 bg-gray-200 px-4 py-2"
              >
                <li v-for="subnavItem in navItem.children" :key="subnavItem.id" class="py-2">
                  <a :href="subnavItem.url">
                    <span class="truncate text-gray-600">{{ subnavItem.title }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
        <div
          class="py-6 border-gray-500 border-b-0 border-t-0 md:border-t md:border-b flex flex-col-reverse md:flex-row justify-between items-start lg:items-center"
        >
          <p class="font-normal mt-8 mb-4 md:mb-0 md:mt-0 text-gray-500"
            >&copy; {{ new Date().getFullYear() }} Haute Living</p
          >
          <div class="flex flex-col-reverse md:flex-row items-start md:items-center">
            <ul
              class="flex flex-col md:flex-row space-x-0 text-gray-500 space-y-2 md:space-y-0 mt-10 md:mt-0"
            >
              <li
                v-for="legalNavItem in columns.legalNavigation"
                :key="legalNavItem.id"
                class="font-normal"
                ><a :href="legalNavItem.url">{{ legalNavItem.title }}</a></li
              >
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <div class="appointmentFormPopup">
      <span class="appointmentFormPopup__close">
        <IconClose class="w-full h-full" />
      </span>
      <MakeAnAppointmentPopup
        :formObj="appform"
        :bannerTitle="'Make an Appointment'"
      ></MakeAnAppointmentPopup>
    </div>
    <div class="mainOverlay"></div>
    <div
      v-if="Object.entries(selectedOptionInfo).length !== 0"
      ref="colorHoverElement"
      class="colorHoverElement"
      :style="`left: ${selectedOptionInfo.left}px; top: ${
        selectedOptionInfo.top + popupInfoHeight
      }px;margin-top:-${goesUp}px;`"
    >
      <div class="colorHoverElement__image">
        <img :src="selectedOptionInfo.image" :alt="selectedOptionInfo.alt" />
      </div>
      <div class="colorHoverElement__text">
        <h3>{{ selectedOptionInfo.title }}</h3>
        <div
          v-if="selectedOptionInfo.text"
          v-html="selectedOptionInfo.text"
          class="text-xs leading-6"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .colorHoverElement {
    position: absolute;
    z-index: 9999;
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    padding: 12px;
    background: #fff;
    border: 1px solid #6f6f6f;
    @media (max-width: 1200px) {
      display: none !important;
    }

    &__image {
      width: 120px;
      height: 120px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text {
      width: calc(100% - 120px);
      padding-left: 15px;

      h3 {
        display: block;
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 600;
        line-height: 13.2px;
        text-transform: uppercase;
      }

      p {
        display: block;
        font-size: 12px;
        line-height: 24px;
      }
    }
  }

  .appointmentFormPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1005;
    display: none;
    width: 850px;
    padding: 40px;
    background: #fff;
    transform: translate(-50%, -50%);

    &__close {
      position: absolute;
      top: 12px;
      right: 15px;
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;
      transform: scale(1.5);
    }
    @media (max-width: 992px) {
      width: 95%;
      max-height: 90%;
      padding: 40px 24px;
      overflow-y: auto;
    }
  }

  .mainOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
  }

  footer {
    position: relative;
    z-index: 1000;
  }
  /* purgecss start ignore */
  .global-footer {
    svg {
      height: auto;

      path {
        transition: fill 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }

  .invert-color {
    filter: brightness(0) invert(1);
  }

  >>> .footer-address {
    font-size: 14px;
    line-height: 17px;

    h3 {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
      letter-spacing: 0.03em;

      a {
        padding-top: 0;
        color: #fff;
      }
    }

    a:last-child {
      display: inline-block;
      padding-top: 10px;
    }
  }

  .footer-wrapper {
    width: 400px;
    @media (max-width: 1200px) {
      width: 350px;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  @screen md {
    .footer-address {
      white-space: nowrap;
    }
  }
  /* purgecss end ignore */
</style>
