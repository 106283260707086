<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseFilterSelectOptions from '@/components/BaseFilterSelectOptions.vue'
  import BaseFilterCheckbox from '@/components/BaseFilterCheckbox.vue'
  import BaseFilterCheckboxGroup from '@/components/BaseFilterCheckboxGroup.vue'
  import BaseFilterSelect from '@/components/BaseFilterSelect.vue'
  import IconFilters from '@/images/icons/filters.svg'
  import BaseModal from '@/components/BaseModal'
  import BaseAccordion from '@/components/BaseAccordion'
  import IconX from '@/images/icons/x.svg'
import { filter } from 'lodash'

  const { mapState, mapGetters, mapActions } = createNamespacedHelpers('products')

  /** The bar of filters used in the PLP */

  export default {
    components: {
      BaseFilterSelectOptions,
      BaseFilterCheckbox,
      BaseFilterCheckboxGroup,
      BaseFilterSelect,
      IconFilters,
      BaseModal,
      BaseAccordion,
      IconX,
    },

    props: {
      /** Whether the filter bar is open or not (mobile view) */
      open: {
        type: Boolean,
        required: false,
      },
      allEmpty:{
        type: Boolean,
        default: false,
      },
      moreWidth: {
        type: Boolean,
        default: false,
      },
      /** The context in which this filter is being used */
      context: {
        type: String,
        required: false,
        default: 'plp',
        validator: (propValue) => {
          const validTypes = ['plp', 'search']
          return validTypes.indexOf(propValue) > -1
        },
      },
      /** The context in which this filter is being used */
      hasHeader: {
        type: Boolean,
        default: false,
      },
      brandId: {
        type: Number,
        default: null,
      },
      cleared: {
        type: String,
        default: '',
      },
      currents: {
        type: Number,
        default: 0,
      },
      isCleared: {
        type: Number,
      },
      type: {
        type: String,
        default: '',
      },
      getIsLoading: {
        type: Boolean,
        default: false,
      },
      giveOpacity: {
        type: Boolean,
        default: false,
      },
      isTradeAccount: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        isOpen: this.open,
        showFilterBlock: false,
        leftPos: '',
        getOpacity: false,
        getLoaded: false,
        priceOptions: [{}],
        chosen: '',
        dimensionShow:true,
        openedAccordions:{},
        isInitialized:false,
        counter:0,
      }
    },

    computed: {
      ...mapState([
        'filterValues',
        'sortOptions',
        'sort',
        'appendFilters',
        'priceElement',
        'priceFilters',
        'dimensionFilters',
        'isLightning',
      ]),
      ...mapGetters([
        'getFilters',
        'getUsedFilters',
        'getFacetedFilterOptions',
        'getFilterEnabledStates',
        'getFilteredProducts',
        'getProducts',
      ]),
      toggleButtonAriaLabel() {
        return this.isOpen ? 'Close filters' : 'Open filters'
      },
      getCheckboxFilterTypes() {
        const checkboxes = this.getFilters.filter(
          (filter) =>
            filter.type === 'checkbox' && (filter.level === 1 || filter.level === undefined)
        )
        return checkboxes
      },
      filterHeight() {
        let pageHeight = window.innerHeight - 72

        if (this.hasHeader) {
          pageHeight = pageHeight - 281
        }

        return pageHeight
      },
      getOnlyTypeFilter() {
        return this.getFilters.filter((item) => item.label === 'Type')
      },
      getNotMaterialFilters() {
        const filters = this.getFilters.filter(
          (item) => item.label !== 'Type' && item.label !== 'Primary' && item.label !== 'Secondary' && item.label !== 'Brand' && item.label !== 'Designer'
        )
        return filters
      },
      getBrandAndDesignerFilters() {
        return this.getFilters.filter(filter => filter.label === 'Designer' || filter.label === 'Brand')
      },
      getFiltersExcludedMaterials() {
        return this.getFilters.filter(
          (item) => item.label !== 'Primary' && item.label !== 'Secondary'
        )
      },
      getOnlyMaterialFilters() {
        return this.getFilters.filter(
          (item) => item.label === 'Primary' || item.label === 'Secondary'
        )
      },
    },

    watch: {
      dimensionFilters() {
        if(this.dimensionFilters.every(item => item.dimensions.length === 0)) {
          this.dimensionShow = false
        }
      },
      appendFilters() {
        if (this.appendFilters.length === 0) {
          this.chosen = ''
        } else {
          const idx = this.appendFilters.findIndex((item) => item.label === this.chosen)
          if (idx < 0) {
            this.chosen = ''
          }
        }
      },
      getIsLoading(item) {
        if (!item) {
          let parsed = []
          const url = new URL(window.location)
          if (url.pathname.indexOf('brand') !== -1) {
            if (url.searchParams.get('brands') !== null) {
              const splitted = url.searchParams.get('brands').split(',')
              parsed = splitted
            } else {
              parsed = [...parsed, this.brandId]
            }
          }
          if (parsed.length !== 0) {
            if (document.querySelector('.scrollFilterWrapper')) {
              if (document.querySelector('.scrollFilterWrapper #brand')) {
                setTimeout(() => {
                  for (let i = 0; i < parsed.length; i++) {
                    if (
                      document.querySelector(
                        '.scrollFilterWrapper input[value="' + parsed[i] + '"]'
                      )
                    ) {
                      document
                        .querySelector('.scrollFilterWrapper input[value="' + parsed[i] + '"]')
                        .click()
                    }
                  }
                }, 3000)
              }
            }
          }
        }
      },
      giveOpacity() {
        this.getOpacity = this.giveOpacity
      },
      open() {
        this.isOpen = this.open
      },
      moreWidth() {
        this.showFilterBlock = this.moreWidth
      },
    },
    mounted() {
      this.filterAnimation()
      this.showFilterBlock = true
      window.addEventListener('resize', this.filterAnimation)
    },
    methods: {
      setOpenedIfFilterLabelEquals(option) {
        return this.appendFilters.some(item => item.type === option)
      },
      filterAnimation() {
        if (window.innerWidth > 1281) {
          this.leftPos = 'margin-left: calc(-20% - 5px);'
        } else {
          this.leftPos = 'margin-left: calc(-25% - 5px);'
        }
      },
      ...mapActions([
        'setFilterValue',
        'clearFilters',
        'setSort',
        'setPriceFilters',
        'setAppendedFilters',
        'remoteSelectedFilter',
        'setChosen',
      ]),
      toggle() {
        this.isOpen = !this.isOpen
        if (this.isOpen) {
          document.querySelector('body').style.overflow = 'hidden'
        } else {
          document.querySelector('body').style.overflow = 'auto'
        }
        this.$emit('toggle', this.isOpen)
      },
      getOptionsCount(handle) {
        return this.getFacetedFilterOptions[handle]
          ? this.getFacetedFilterOptions[handle].length
          : null
      },
      getTypeCount(handle) {
        return this.getProducts.filter((product) => product[handle] === true).length
      },
      // Retrieves product status from URL query param
      getStatusFromUrl() {
        const uri = window.location.search.substring(1)
        const params = new URLSearchParams(uri)

        return params.get('s')
      },
      getProductStatusHandle(status) {
        switch (status) {
          case 'inshowroom':
            return 'inShowroom'
          case 'quickship':
            return 'quickShip'
          case 'ullisted':
            return 'ulListed'
          case 'new':
            return 'new'
          case 'buynow':
            return 'buyNow'
          case 'newyork':
            return 'newYork'
          default:
            return status
        }
      },
      setFilterProductStatus(handle, event, filter) {
        this.setFilterValue({
          filter: this.getProductStatusHandle(this.getStatusFromUrl()),
          value: false,
        })

        if (event) {
          const url = new URL(window.location)
          url.searchParams.set('s', handle.toLowerCase())
          window.history.pushState({}, '', url)
          this.chosen = handle.toLowerCase()
          this.setChosen(handle)
        } else {
          const url = new URL(window.location)
          url.searchParams.delete('s')
          window.history.pushState({}, '', url)
          this.setChosen('')
        }

        this.setFilterValue({ filter: this.getProductStatusHandle(handle), value: event })
      },
      setPriceProducts(handle, min, max) {
        if (this.priceElement.title === handle) {
          this.setPriceFilters({})
        } else {
          this.setPriceFilters({ title: handle, min, max })
        }
      },
      hasActiveSubcategory(handle) {
        const parent = this.getFilters.filter((filter) => filter.property === handle)[0]
        let activeChild = {}

        if (!this.getUsedFilters.length) {
          return false
        }

        this.getUsedFilters.forEach((usedFilter) => {
          activeChild = parent.children.filter(
            (child) => usedFilter.property === child.property && child.level > 1
          )[0]
        })

        return !!activeChild
      },
    },
  }
</script>

<template>
  <div
    class="z-30 sticky bg-white md:grid md:col-span-1 w-full lg:w-3/12 xl:w-1/5 stickySidebar"
    :class="{
      'bar-search': context === 'search',
      bar: context === 'plp',
      'has--transition': getIsLoading === false,
      'has--opacity': getOpacity === true,
    }"
    :style="`${showFilterBlock ? leftPos : ''}`"
  >
    <div class="mx-0 flex-col justify-between lg:hidden filterButton overflow-hidden">
      <button
        class="flex items-center px-3 py-2 w-full border rounded text-base mt-2"
        @click="toggle"
      >
        <IconFilters class="mr-3 w-5 h-5 flex-shrink-0 fill-current" />
        <p class="flex items-center justify-center">
          <span class="font-normal show--filters">Show Filters</span>
          <span
            v-if="getUsedFilters.length > 0"
            class="w-5 h-5 bg-black rounded-full text-white inline-block ml-3 flex items-center justify-center"
          >
            {{ getUsedFilters.length }}
          </span>
        </p>
      </button>
      <BaseFilterSelect
        v-if="isTradeAccount"
        label="Sort by"
        :options="sortOptions"
        :value="sort"
        class="lg:pr-6"
        @change="setSort($event)"
      />
    </div>

    <BaseModal v-if="isOpen">
      <template v-slot:body>
        <header
          class="flex justify-between items-center border-b border-gray-200 modal-header pb-6 mobileFilterBody"
        >
          <p class="font-normal tracking-wide hide--filters">Hide Filters</p>

          <button
            ref="closeButton"
            type="button"
            class="flex justify-center items-center overflow-hidden modal-close"
            tabindex="0"
            @click="toggle"
          >
            <IconX class="fill-current" />
          </button>
        </header>

        <div class="py-3">
          <div v-for="filter in getCheckboxFilterTypes.filter(item => item.handle !== 'pcon')"
            :key="filter.label">
            <BaseFilterCheckboxGroup
              v-if="filter.level && filter.children && filter.children.length"
              :label="`${filter.label}`"
              :available-items="getTypeCount(filter.handle)"
              :children="filter.children ? filter.children : []"
              :handle="filter.handle"
              :filter-values="filterValues"
              :filter-enabled-states="getFilterEnabledStates"
              :filtered-products="getFilteredProducts"
              :products="getProducts"
              :checked="
                filterValues[filter.handle] ||
                (getFilterEnabledStates[filter.handle] === false &&
                  getFilteredProducts.length > 0 &&
                  getFilteredProducts[0][filter.property] === true) ||
                hasActiveSubcategory(filter.handle)
              "
              @change="
                setFilterProductStatus(
                  $event.handle,
                  $event.event.event ? $event.event.event : $event.event
                )
              "
            />
            <BaseFilterCheckbox
              v-else-if="
                (getTypeCount(filter.handle) !== false && getTypeCount(filter.handle) > 0) ||
                (filter.showWhenNoResults !== undefined && filter.showWhenNoResults === true)
              "
              :label="`${filter.label}`"
              :available-items="getTypeCount(filter.handle)"
              :children="filter.children ? filter.children : []"
              :handle="filter.handle"
              :checked="
                filterValues[filter.handle] ||
                (getFilterEnabledStates[filter.handle] === false &&
                  getFilteredProducts.length > 0 &&
                  getFilteredProducts[0][filter.property] === true)
              "
              @change="setFilterProductStatus(filter.handle, $event)"
            />
          </div>
        </div>
        <div v-for="filter in getFiltersExcludedMaterials" :key="filter.handle">
          <BaseAccordion v-if="getOptionsCount(filter.handle) > 0">
            <template v-slot:heading>
              <p class="font-medium tracking-widest uppercase" style="font-size: 16px;">
                <span>
                  {{ filter.label }}
                </span>
                ({{ getOptionsCount(filter.handle) }})
              </p>
            </template>
            <template v-slot:content>
              <div>
                <BaseFilterSelectOptions
                  :label="filter.label"
                  :options="getFacetedFilterOptions[filter.handle]"
                  :value="filterValues[filter.handle] ? filterValues[filter.handle] : []"
                  :disabled="getFilterEnabledStates[filter.handle] === false"
                  @change="setFilterValue({ filter: filter.handle, value: $event })"
                />
              </div>
            </template>
          </BaseAccordion>
        </div>

        <!-- Materials filter -->
        <BaseAccordion
          v-if="
            getOptionsCount('primaryMaterials') > 0 || getOptionsCount('secondaryMaterials') > 0
          "
          :nested="true"
        >
          <template v-slot:heading>
            <p style="font-size: 16px;">
              <span class="font-medium tracking-widest uppercase"> Materials </span>
            </p>
          </template>
          <template v-slot:content>
            <div v-for="filter in getOnlyMaterialFilters" :key="filter.handle">
              <BaseAccordion v-if="getOptionsCount(filter.handle) > 0" :data-id="filter.handle" class="-mx-5 pt-0 bg-black">
                <template v-slot:heading>
                  <p class="pl-3">
                    <span class="font-bold">
                      {{ filter.label }}
                    </span>
                    <span v-if="filter.showCount"> ({{ getOptionsCount(filter.handle) }}) </span>
                  </p>
                </template>
                <template v-slot:content>
                  <div class="pl-3">
                    <BaseFilterSelectOptions
                      :label="filter.label"
                      :options="getFacetedFilterOptions[filter.handle]"
                      :value="filterValues[filter.handle] ? filterValues[filter.handle] : []"
                      :disabled="getFilterEnabledStates[filter.handle] === false"
                      @change="setFilterValue({ filter: filter.handle, value: $event })"
                    />
                  </div>
                </template>
              </BaseAccordion>
            </div>
          </template>
        </BaseAccordion>

        <!-- Prices filter -->
        <BaseAccordion v-if="isTradeAccount || isLightning">
          <template v-slot:heading>
            <p style="font-size: 16px;">
              <span class="font-medium tracking-widest uppercase"> Price </span>
            </p>
          </template>
          <template v-slot:content>
            <div>
              <div v-for="filter in priceFilters" :key="filter.label">
                <BaseFilterCheckbox
                  :label="filter.label"
                  :handle="filter.value"
                  :checked="priceElement.title === filter.label"
                  @change="setPriceProducts(filter.label, filter.min, filter.max)"
                />
              </div>
            </div>
          </template>
        </BaseAccordion>

        <footer class="border-t border-gray-200">
          <div class="pt-6">
            <BaseButton
              :color="'black'"
              :full-width="true"
              class="mb-6"
              additional-btn-classes="rounded font-bold"
              :disabled="getUsedFilters.length === 0"
              @click.native="clearFilters"
            >
              Clear Filters
            </BaseButton>

            <BaseButton
              :color="'black'"
              :full-width="true"
              additional-btn-classes="rounded font-bold"
              @click.native="toggle"
            >
              View Results
            </BaseButton>
          </div>
        </footer>
      </template>
    </BaseModal>

    <div class="hidden lg:block stickySidebar--inner">
      <!-- <div class="relative w-full py-4 px-6 bg-black border border-gray-300">
        <span class="text-white">Filter</span>
        <IconX
          class="fill-current text-white w-3 h-3 absolute right-6 top-5 cursor-pointer"
          @click="showHideMe"
        />
      </div> -->
      <div class="scrollFilterWrapper overflow-hidden pb-1">
        <div
          v-for="(filter, filterIndex) in getOnlyTypeFilter"
          :key="filter.handle"
          :class="filterIndex === 0 ? '' : ''"
        >
          <BaseAccordion v-if="getOptionsCount(filter.handle) > 0">
            <template v-slot:heading>
              <p>
                <span class="font-medium uppercase tracking-wider"> {{ filter.label }} </span>
                <span v-if="filter.showCount"> ({{ getOptionsCount(filter.handle) }}) </span>
              </p>
            </template>
            <template v-slot:content>
              <div>
                <BaseFilterSelectOptions
                  :label="filter.label"
                  :options="getFacetedFilterOptions[filter.handle]"
                  :value="filterValues[filter.handle] ? filterValues[filter.handle] : []"
                  :disabled="getFilterEnabledStates[filter.handle] === false"
                  @change="setFilterValue({ filter: filter.handle, value: $event })"
                />
              </div>
            </template>
          </BaseAccordion>
        </div>

        <div
          v-for="(filter, filterIndex) in getNotMaterialFilters"
          :key="filter.handle"
          :class="filterIndex === 0 ? '' : ''"
          :id="filter.handle"
        >
          <BaseAccordion v-if="getOptionsCount(filter.handle) > 0" :data-id="filter.handle">
            <template v-slot:heading>
              <p>
                <span class="font-medium uppercase tracking-wider"> {{ filter.label }}</span>
                <span v-if="filter.showCount"> ({{ getOptionsCount(filter.handle) }}) </span>
              </p>
            </template>
            <template v-slot:content>
              <div>
                <BaseFilterSelectOptions
                  :label="filter.label"
                  :options="getFacetedFilterOptions[filter.handle]"
                  :value="filterValues[filter.handle] ? filterValues[filter.handle] : []"
                  :disabled="getFilterEnabledStates[filter.handle] === false"
                  @change="setFilterValue({ filter: filter.handle, value: $event })"
                />
              </div>
            </template>
          </BaseAccordion>
        </div>

        <!-- Materials filter -->
        <BaseAccordion
          v-if="
            getOptionsCount('primaryMaterials') > 0 || getOptionsCount('secondaryMaterials') > 0
          "
          :nested="true"
          :data-id="'materials'"
        >
          <template v-slot:heading>
            <p>
              <span class="font-medium uppercase tracking-wider"> Materials </span>
            </p>
          </template>
          <template v-slot:content>
            <div
              v-for="(filter, filterIndex) in getOnlyMaterialFilters"
              :key="filter.handle"
              :class="filterIndex === 0 ? '' : ''"
              :id="filter.handle"
            >
              <BaseAccordion v-if="getOptionsCount(filter.handle) > 0" :data-id="filter.handle" class="-mx-5">
                <template v-slot:heading>
                  <p class="pl-3">
                    <span class="font-medium uppercase tracking-wider">
                      {{ filter.label }}
                    </span>
                    <span v-if="filter.showCount"> ({{ getOptionsCount(filter.handle) }}) </span>
                  </p>
                </template>
                <template v-slot:content>
                  <div class="pl-3">
                    <BaseFilterSelectOptions
                      :label="filter.label"
                      :options="getFacetedFilterOptions[filter.handle]"
                      :value="filterValues[filter.handle] ? filterValues[filter.handle] : []"
                      :disabled="getFilterEnabledStates[filter.handle] === false"
                      @change="setFilterValue({ filter: filter.handle, value: $event })"
                    />
                  </div>
                </template>
              </BaseAccordion>
            </div>
          </template>
        </BaseAccordion>

        <!-- Dimension filter -->
        <BaseAccordion v-if="dimensionShow" :nested="true">
          <template v-slot:heading>
            <p>
              <span class="font-medium uppercase tracking-wider"> Dimensions </span>
            </p>
          </template>
          <template v-slot:content>
            <div
              v-for="(filter, filterIndex) in dimensionFilters" :key="filterIndex"
            >
              <BaseAccordion class="-mx-5" v-if="filter.dimensions.length > 0">
                <template v-slot:heading>
                  <p class="pl-3">
                    <span class="font-medium uppercase tracking-wider">
                      {{ filter.title }}
                    </span>
                  </p>
                </template>
                <template v-slot:content>
                  <div class="pl-3">
                    <BaseFilterSelectOptions
                      :label="filter.title"
                      :options="filter.dimensions.length !== 0 ? filter.dimensions : []"
                      :value="filterValues[filter.title] ? filterValues[filter.title] : []"
                      @change="setFilterValue({ filter: filter.title, value: $event })"
                    />
                  </div>
                </template>
              </BaseAccordion>
            </div>
          </template>
        </BaseAccordion>

        <!-- Prices filter -->
        <BaseAccordion v-if="isTradeAccount || isLightning">
          <template v-slot:heading>
            <p>
              <span class="font-medium uppercase tracking-wider"> Price </span>
            </p>
          </template>
          <template v-slot:content>
            <div>
              <div v-for="filter in priceFilters" :key="filter.label">
                <BaseFilterCheckbox
                  :label="filter.label"
                  :handle="filter.value"
                  :checked="priceElement.title === filter.label"
                  @change="setPriceProducts(filter.label, filter.min, filter.max)"
                />
              </div>
            </div>
          </template>
        </BaseAccordion>

        <BaseAccordion :data-id="'availability'">
          <template v-slot:heading>
            <p>
              <span class="font-medium uppercase tracking-wider"> Availability </span>
            </p>
          </template>
          <template v-slot:content>
            <div>
              <div
                v-for="filter in getCheckboxFilterTypes.filter(
                  (filter) => filter.handle !== 'quickShip' && filter.handle !== 'pcon'
                )"
                :key="filter.label"
              >
                <BaseFilterCheckboxGroup
                  v-if="filter.level && filter.children && filter.children.length"
                  :label="`${filter.label}`"
                  :available-items="getTypeCount(filter.handle)"
                  :children="filter.children ? filter.children : []"
                  :handle="filter.handle"
                  :filter-values="filterValues"
                  :filter-enabled-states="getFilterEnabledStates"
                  :filtered-products="getFilteredProducts"
                  :products="getProducts"
                  :show-count="filter.showCount"
                  :show-when-selected="filter.showWhenSelected"
                  :checked="
                    filterValues[filter.handle] ||
                    (getFilterEnabledStates[filter.handle] === false &&
                      getFilteredProducts.length > 0 &&
                      getFilteredProducts[0][filter.handle] === true) ||
                    hasActiveSubcategory(filter.handle)
                  "
                  @change="
                    setFilterProductStatus(
                      $event.handle,
                      $event.event.event ? $event.event.event : $event.event
                    )
                  "
                />
                <BaseFilterCheckbox
                  v-else-if="
                    (getTypeCount(filter.handle) !== false && getTypeCount(filter.handle) > 0) ||
                    (filter.showWhenNoResults !== undefined && filter.showWhenNoResults === true)
                  "
                  :label="`${filter.label}`"
                  :available-items="
                    filter.showCount ? getTypeCount(filter.handle) : filter.showCount
                  "
                  :children="filter.children ? filter.children : []"
                  :handle="filter.handle"
                  :checked="
                    filterValues[filter.handle] ||
                    (getFilterEnabledStates[filter.handle] === false &&
                      getFilteredProducts.length > 0 &&
                      filter.property.toLowerCase() === chosen)
                  "
                  @change="setFilterProductStatus(filter.handle, $event, filter)"
                />
              </div>
            </div>
          </template>
        </BaseAccordion>
        <div
          v-for="(filter, filterIndex) in getBrandAndDesignerFilters"
          :key="filter.handle"
          :class="filterIndex === 0 ? '' : ''"
        >
          <BaseAccordion v-if="getOptionsCount(filter.handle) > 0" :data-id="filter.handle">
            <template v-slot:heading>
              <p>
                <span class="font-medium uppercase tracking-wider"> {{ filter.label }}</span>
                <span v-if="filter.showCount"> ({{ getOptionsCount(filter.handle) }}) </span>
              </p>
            </template>
            <template v-slot:content>
              <div>
                <BaseFilterSelectOptions
                  :label="filter.label"
                  :options="getFacetedFilterOptions[filter.handle]"
                  :value="filterValues[filter.handle] ? filterValues[filter.handle] : []"
                  :disabled="getFilterEnabledStates[filter.handle] === false"
                  @change="setFilterValue({ filter: filter.handle, value: $event })"
                />
              </div>
            </template>
          </BaseAccordion>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .hide--filters,
  .show--filters
  {
    font-size: 16px;
  }
  .show--filters {
    position: relative;
    top: 2px;
  }
  .stickySidebar--inner {
    padding-top: 5px;
  }

  .stickySidebar {
    opacity: 0;

    &.has--opacity {
      opacity: 1;
    }

    &.has--transition {
      transition: all 0.25s ease-in-out;
    }
    @media (max-width: 1024px) {
      opacity: 1;
    }
  }

  .scrollFilterWrapper {
    padding-bottom: 2px;
  }

  .scrollFilterWrapper__inner::-webkit-scrollbar {
    width: 5px;
  }

  .scrollFilterWrapper__inner::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollFilterWrapper__inner::-webkit-scrollbar-thumb {
    background-color: #6f6f6f;
    border-radius: 100px;
    outline: none;
  }

  .bar {
    top: theme('spacing.header-mobile');

    @media (max-width: 1024px) {
      top: 0;
    }
  }

  .bar-search {
    top: 0;

    @screen lg {
      top: calc(theme('spacing.header-desktop') + 46px);
    }
  }

  .bar-filter {
    @apply min-w-1/2 flex-1 border-b border-gray-300;
    @screen xs {
      @apply min-w-1/3;
    }
    @screen lg {
      @apply min-w-0 flex-auto;
    }

    & + .bar-filter {
      @apply border-l;
    }
  }

  .modal-close {
    span {
      position: absolute;
      left: -9999px;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  >>> .modal-body {
    max-height: 100%;
  }
</style>
