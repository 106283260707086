const recaptchaService = {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,

  async loadRecaptcha() {
    return new Promise((resolve) => {
      if (window.grecaptcha && window.grecaptcha.ready) {
        window.grecaptcha.ready(() => {
          resolve();
        });
        return;
      }

      let attempts = 0;
      const maxAttempts = 50;

      const checkRecaptcha = setInterval(() => {
        attempts++;
        
        if (window.grecaptcha && window.grecaptcha.ready) {
          clearInterval(checkRecaptcha);
          window.grecaptcha.ready(() => {
            resolve();
          });
        }

        if (attempts >= maxAttempts) {
          clearInterval(checkRecaptcha);
          resolve();
        }
      }, 100);
    });
  },

  async executeRecaptcha(action) {
    try {
      await this.loadRecaptcha();
      
      if (!window.grecaptcha || !window.grecaptcha.execute) {
        return null;
      }

      const token = await window.grecaptcha.execute(this.siteKey, { action });
      return token;
    } catch (error) {
      return null;
    }
  }
};

export default recaptchaService; 