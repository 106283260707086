<script>
  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')

  export default {
    name: 'ProductSpecs',

    props: {
      downloads: {
        type: Array,
        required: false,
        default: () => [],
      },
      loggedIn: {
        type: Boolean,
        default: false,
      },
      dimensions: {
        type: Array,
        default: () => [],
      },
      showNewLayout: {
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        isInches: true,
        score: 0.393701,
        specExtensions: [],
        otherExtensions: [],
        formattedDimensions:[],
        sameSeat: false,
        firstSeat: null
      }
    },
    mounted() {
      if(this.dimensions.length > 0) {
        this.formattedDimensions = this.dimensions.map(item => {
          const onlySeat = item.items.filter(it => it.name === 'Seat Height')
          let seatObject = null
          const formattedItems = item.items.filter(it => it.name !== 'Seat Height')
          const shortTitles = formattedItems.map(dim => {
            let shortTitle = ''
            switch(dim.name) {
              case 'Diameter':
                shortTitle = 'Ø'
                break;
              case 'Height':
                shortTitle = 'H'
                break;
              case 'Length / Width':
                shortTitle = 'L/W'
                break;
              case 'Depth':
                shortTitle = 'D'
                break;
              case 'Seat Height':
                shortTitle = 'Seat Height'
            }
            return {
              ...dim,
              short: shortTitle
            }
          })
          if(onlySeat.length > 0) {
            const firstEl = onlySeat[0]
            if(firstEl.min || firstEl.max) {
              seatObject = firstEl
            }
          }
          return {
            title: item.title,
            items: shortTitles,
            seat: seatObject
          }
        })
        // const foundSeat = this.formattedDimensions.filter(item => item.seat)
        // if(foundSeat.length > 0) {
        //   const sameNum = foundSeat.filter(item => item.seat.min)[0]
        //   console.log(foundSeat)
        //   const seatsAreSame = this.formattedDimensions.every(it => {
        //     return it.seat.min === sameNum
        //   })
        //   if(seatsAreSame) {
        //     this.firstSeat = foundSeat[0]
        //     this.sameSeat = true
        //   }
        // }
      }
      this.downloads.forEach((item) => {
        if (item.extension !== 'pdf') {
          this.specExtensions.push(item)
        } else {
          this.otherExtensions.push(item)
        }
      })
    },
    methods: {
      switchUnit() {
        this.isInches = !this.isInches
      },
    },
  }
</script>

<template>
  <section
    v-if="
      $slots.details || $slots.dimensions || $slots.materials || $slots.finishes || downloads.length
    "
    :class="`${ showNewLayout ? 'xl:grid xl:grid-cols xl:gap-6 py-6' : 'pb-6 border-b pt-6 xl:grid xl:grid-cols-8 xl:gap-6' }`"
  >
    <div v-if="showNewLayout">
      <h2 class="mb-6 xl:col-span-2 hidden">Specs</h2>

      <div class="xl:col-span-12">
        <dl class="wysiwyg">
          <dt v-if="$slots.details" class="mb-2 font-semibold uppercase">Details</dt>
          <dd v-if="$slots.details" class="mb-6 text-gray-600 leading-snug">
            <slot name="details"></slot>
          </dd>

          <div v-if="dimensions.length > 0">
            <div class="w-full flex flex-row justify-between items-center pt-0 mb-8">
              <h3 class="mb-0 font-semibold uppercase">Dimensions</h3>
              <div class="unit--switcher">
                <i>Cm</i>
                <div class="switch--button">
                  <input type="checkbox" name="unitswitch" @change="switchUnit" checked />
                  <span></span>
                </div>
                <i>In</i>
              </div>
            </div>
            <div class="specs--content flex flex-col justify-start">
              <div v-for="spec in formattedDimensions" :key="spec.title" class="w-full mb-4">
                <h4 class="block w-full text-sm font-medium">{{
                  spec.customName ? spec.customName : spec.title
                }}</h4>
                <ul class="flex flex-wrap">
                  <li class="mr-1 text-sm specItem" v-for="(item, idx) in spec.items" :key="item.name">
                    <em class="not-italic" v-if="item.min && item.max">
                      {{ isInches ? (item.min * score).toFixed(1) : item.min }} - {{ isInches ? (item.max * score).toFixed(1) : item.max }}{{ isInches ? '"' : ' cm' }}
                    </em>
                    <em class="not-italic" v-else-if="item.min && !item.max">
                      {{ isInches ? (item.min * score).toFixed(1) : item.min }}{{ isInches ? '"' : ' cm' }}
                    </em>
                    <em class="not-italic" v-else-if="!item.min && item.max">
                      {{ isInches ? (item.max * score).toFixed(1) : item.max }}{{ isInches ? '"' : ' cm' }}
                    </em>
                    <em class="not-italic"
                      > {{ item.short }}
                    </em>
                    <i v-if="idx !== spec.items.length - 1">x</i>
                  </li>
                </ul>
                <div v-if="spec.seat">
                  <em class="not-italic text-sm font-medium">{{ spec.seat.name }}: </em>
                  <em class="not-italic text-sm" v-if="spec.seat.min && spec.seat.max">
                    {{ isInches ? (spec.seat.min * score).toFixed(1) : spec.seat.min }} - {{ isInches ? (spec.seat.max * score).toFixed(1) : spec.seat.max }}{{ isInches ? '"' : 'cm' }}
                  </em>
                  <em class="not-italic text-sm" v-else-if="spec.seat.min && !spec.seat.max">
                    {{ isInches ? (spec.seat.min * score).toFixed(1) : spec.seat.min }}{{ isInches ? '"' : ' cm' }}
                  </em>
                  <em class="not-italic text-sm" v-else-if="!spec.seat.min && spec.seat.max">
                    {{ isInches ? (spec.seat.max * score).toFixed(1) : spec.seat.max }}{{ isInches ? '"' : ' cm' }}
                  </em>
                </div>
              </div>
            </div>
            <!-- <div v-if="sameSeat" class="specs--content flex flex-col justify-start">
              <div class="mb-3 w-full">
                <h4 class="block w-full text-sm font-medium">Seat Height</h4>
                <ul class="flex flex-wrap">
                  <li class="mr-1 text-sm specItem" v-for="(item, idx) in firstSeat.items" :key="item.name">
                    <em class="not-italic" v-if="item.min && item.max">
                      {{ isInches ? (item.min * score).toFixed(1) : item.min }} - {{ isInches ? (item.max * score).toFixed(1) : item.max }}{{ isInches ? '"' : ' cm' }}
                    </em>
                    <em class="not-italic" v-else-if="item.min && !item.max">
                      {{ isInches ? (item.min * score).toFixed(1) : item.min }}{{ isInches ? '"' : ' cm' }}
                    </em>
                    <em class="not-italic" v-else-if="!item.min && item.max">
                      {{ isInches ? (item.max * score).toFixed(1) : item.max }}{{ isInches ? '"' : ' cm' }}
                    </em>
                    <em class="not-italic"
                      > {{ item.customName ? item.customName : item.name }}
                    </em>
                    <i v-if="idx !== firstSeat.items.length - 1">x</i>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>

          <dt v-if="$slots.materials" class="mb-2 font-semibold uppercase">Materials</dt>
          <dd v-if="$slots.materials" class="mb-6 text-gray-600 leading-snug">
            <slot name="materials"></slot>
          </dd>

          <dt v-if="$slots.finishes" class="mb-2 font-semibold uppercase">Finishes</dt>
          <dd v-if="$slots.finishes" class="mb-6 text-gray-600 leading-snug">
            <slot name="finishes"></slot>
          </dd>

          <!-- <dt
            v-if="otherExtensions.length || specExtensions.length"
            class="mb-2 font-semibold uppercase"
            >Downloads</dt
          >
          <dd
            v-if="otherExtensions.length || specExtensions.length"
            class="text-gray-600 leading-snug"
          >
            <ul>
              <li v-for="(file, index) in otherExtensions" :key="index">
                <a :href="file.url" target="_blank" class="download--btn">
                  {{ file.label }} ({{ file.extension.toUpperCase() }})
                </a>
              </li>
            </ul>
            <ul v-if="loggedIn">
              <li v-for="(file, index) in specExtensions" :key="index">
                <a :href="file.url" target="_blank" class="download--btn">
                  {{ file.label }} ({{ file.extension.toUpperCase() }})
                </a>
              </li>
            </ul>
          </dd> -->
        </dl>
      </div>
    </div>
    <div class="xl:col-span-12" v-else>
      <dl class="wysiwyg">
        <dt v-if="$slots.details" class="mb-2 font-bold">Details</dt>
        <dd v-if="$slots.details" class="mb-6 text-black leading-snug">
          <slot name="details"></slot>
        </dd>

        <dt v-if="$slots.dimensions" class="mb-2 font-bold">Dimensions</dt>
        <dd v-if="$slots.dimensions" class="mb-6 text-black leading-snug">
          <slot name="dimensions"></slot>
        </dd>

        <dt v-if="$slots.materials" class="mb-2 font-bold">Materials</dt>
        <dd v-if="$slots.materials" class="mb-6 text-black leading-snug">
          <slot name="materials"></slot>
        </dd>

        <dt v-if="$slots.finishes" class="mb-2 font-bold">Finishes</dt>
        <dd v-if="$slots.finishes" class="mb-6 text-black leading-snug">
          <slot name="finishes"></slot>
        </dd>

        <!-- <dt
          v-if="otherExtensions.length || specExtensions.length"
          class="mb-2 font-semibold uppercase"
          >Downloads</dt
        >
        <dd
          v-if="otherExtensions.length || specExtensions.length"
          class="text-gray-600 leading-snug"
        >
          <ul>
            <li v-for="(file, index) in otherExtensions" :key="index">
              <a :href="file.url" target="_blank" class="download--btn">
                {{ file.label }} ({{ file.extension.toUpperCase() }})
              </a>
            </li>
          </ul>
          <ul v-if="loggedIn">
            <li v-for="(file, index) in specExtensions" :key="index">
              <a :href="file.url" target="_blank" class="download--btn">
                {{ file.label }} ({{ file.extension.toUpperCase() }})
              </a>
            </li>
          </ul>
        </dd> -->
      </dl>
    </div>
  </section>
</template>

<style scoped lang="postcss">
  .specs--content {
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .specItem {
    i {
      font-style: normal;
    }
  }

  .unit--switcher {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100px;

    i {
      font: 500 12px/12px 'Inter', sans-serif;
      font-style: normal;
      text-transform: uppercase;
    }

    .switch--button {
      position: relative;
      width: 40px;
      height: 22px;
      overflow: hidden;
      background: #000;
      border-radius: 50px;

      input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: transparent;
        opacity: 0;
      }

      span {
        position: absolute;
        top: 2px;
        left: 2.3px;
        display: block;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 100%;
        transition: all 0.25s ease-in-out;
      }
    }

    .switch--button input:checked + span {
      left: 20px;
    }
  }

  .wysiwyg a {
    border-color: #6f6f6f;
    border-style: solid;
  }

  .wysiwyg a.download--btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: rgb(202, 24, 24);
    border-color: rgb(202, 24, 24);

    &:hover {
      border-color: transparent;
    }
  }
</style>
