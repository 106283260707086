<script>
import BaseCountButton from '@/components/BaseCountButton'

export default {
  name: 'NewSearchViewToggle',

  components: {
    BaseCountButton,
  },

  props: {
    view: {
      type: String,
      required: true,
    },
    productsCount: {
      type: Number,
      required: true,
    },
    brandsCount: {
      type: Number,
      required: true,
    },
    designersCount: {
      type: Number,
      required: true,
    },
    articlesCount: {
      type: Number,
      required: true,
    },
  },

  methods: {
    switchView(view) {
      this.$emit('update:view', view)
    },
  },
}
</script>

<template>
  <ul class="z-50 sticky flex flex-wrap justify-between bg-white -mx-3 md:justify-start toggle md:-mx-5">
    <li v-if="productsCount" class="block w-1/2 border-r border-b border-gray-300 md:w-auto md:border-0">
      <BaseCountButton
        label="Products"
        :count="productsCount"
        value="products"
        :is-active="view === 'products'"
        @button-click="switchView"
      />
    </li>

    <li v-if="brandsCount" class="block w-1/2 border-r border-b border-gray-300 md:w-auto md:border-0">
      <BaseCountButton
        label="Brands"
        :count="brandsCount"
        value="brands"
        :is-active="view === 'brands'"
        @button-click="switchView"
      />
    </li>

    <li v-if="designersCount" class="block w-1/2 border-r border-b border-gray-300 md:w-auto md:border-0">
      <BaseCountButton
        label="Designers"
        :count="designersCount"
        value="designers"
        :is-active="view === 'designers'"
        @button-click="switchView"
      />
    </li>

    <li v-if="articlesCount" class="block w-1/2 border-r border-b border-gray-300 md:w-auto md:border-0">
      <BaseCountButton
        label="Inspiration"
        :count="articlesCount"
        value="articles"
        :is-active="view === 'articles'"
        @button-click="switchView"
      />
    </li>
  </ul>
</template>

<style scoped lang="postcss">
.toggle {
  top: calc(theme('spacing.header-mobile') - 1px);

  @screen lg {
    top: theme('spacing.header-desktop');
  }
}
</style>
