<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseInputQuantity from '@/components/BaseInputQuantity'
  import CartPreview from '@/components/cart/CartPreview'
  import CartRemoveButton from '@/components/cart/CartRemoveButton'
  import CartLineItemPrices from '@/components/cart/CartLineItemPrices'

  /** A full version of the cart line items (used in the cart page) */

  const { mapGetters } = createNamespacedHelpers('cart')

  export default {
    name: 'CartLineItemsFull',

    components: {
      BaseInputQuantity,
      CartLineItemPrices,
      CartPreview,
      CartRemoveButton,
    },

    props: {
      /** Whether or not the item quantities should be editable */
      editable: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    data() {
      return {
        tradeAccount: false,
      }
    },

    computed: {
      /** Vuex getters */
      ...mapGetters(['getIsLoading', 'getItems']),

      /** Ensures reactivity by creating a computed version of `getItems` */
      visibleItems() {
        return [...this.getItems]; // Spread to force Vue to track changes
      }
    },

    watch: {
      /** Watches `getItems` for updates and logs changes */
      getItems(newItems) {
        console.log('Updated Cart Items:', newItems);
      },
    },

    created() {
      console.log('Initializing CartLineItemsFull.vue');
      this.tradeAccount = this.$store.getters['global/isTradeAccount'];

      console.log('Trade Account:', this.tradeAccount);
      console.log('Initial Cart Items:', this.getItems);
      
      // Ensure cart items are loaded
      if (!this.getItems.length) {
        this.$store.dispatch('cart/fetchCartItems');
      }
    },

    methods: {
      /** Change the line item quantity */
      changeLineItemQty(event) {
        this.$store.dispatch('cart/changeItemQty', { id: event.id, qty: event.count });
      },

      /** Remove the line item */
      removeLineItem(event) {
        this.$store.dispatch('cart/changeItemQty', { id: event, qty: 0 });
      },
    },
  }
</script>

<template>
  <table class="w-full table-auto">
    <thead>
      <tr>
        <th class="pl-4 py-3 pr-2 text-sm font-normal text-left text-black | lg:px-6">Item</th>
        <th class="px-6 py-3 text-sm font-normal text-right text-black || hidden-col">Price</th>
        <th class="py-3 text-sm font-normal text-center text-black | lg:px-6">Quantity</th>
        <th class="pl-2 py-3 pr-4 text-sm font-normal text-right text-black | lg:px-6">Subtotal</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in visibleItems" :key="index">
        <td class="pl-4 py-4 pr-2 align-top border-gray-200 border-t | lg:px-6">
          <CartPreview v-bind="item" />
        </td>
        <td class="px-6 py-4 align-top text-right border-gray-200 border-t || hidden-col">
          <CartLineItemPrices
            :price="item.unitPrice"
            :sales="
              tradeAccount
                ? [
                    item.unitSalePrices
                      .filter((sale) => sale.isTrade)
                      .reduce((prev, current) => (prev.price > current.price ? prev : current), { price: 0 })
                  ]
                : []
            "
            :trade-account="tradeAccount"
          />
        </td>
        <td class="py-4 align-top text-center border-gray-200 border-t | lg:px-6">
          <div v-if="editable" class="flex flex-col justify-start items-center">
            <BaseInputQuantity
              :id="item.id"
              :value="item.qty"
              :min="0"
              :max="99"
              :disabled="getIsLoading"
              @increment="changeLineItemQty"
              @decrement="changeLineItemQty"
            />
            <CartRemoveButton :id="item.id" @removeItem="removeLineItem" />
          </div>
          <span v-else class="text-xs | xl:text-base">{{ item.qty }}</span>
        </td>
        <td class="pl-2 py-4 pr-4 align-top text-right border-gray-200 border-t | lg:px-6">
          <CartLineItemPrices
            :price="item.lineItemPrice"
            :sales="
              tradeAccount
                ? [
                    item.lineItemSalePrices
                      .filter((sale) => sale.isTrade)
                      .reduce((prev, current) => (prev.price > current.price ? prev : current), { price: 0 })
                  ]
                : []
            "
            :trade-account="tradeAccount"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="postcss">
  .hidden-col {
    display: none;
  }

  @screen xl {
    .hidden-col {
      display: table-cell;
    }
  }
</style>
