<script>
    import BasePageHeader from '@/components/BasePageHeader'
    import NewSearchViewToggle from '@/components/search/NewSearchViewToggle'
    import algoliasearch from 'algoliasearch/lite'
    import BaseCountButton from '@/components/BaseCountButton'
    import BrandTile from '@/components/brands/BrandTile'
    import DesignerTile from '@/components/designers/DesignerTile'
    import ArticleTile from '@/components/articles/ArticleTile'

    export default {
      name: 'SearchResultsPage',

      components: {
        BasePageHeader,
        NewSearchViewToggle,
        BaseCountButton,
        BrandTile,
        DesignerTile,
        ArticleTile,
      },

      props: {
        query: {
          type: String,
          required: true,
        },
      },

  data() {
    return {
      results: {
        products: { all: [], displayed: [], total: 0 },
        brands: { all: [], displayed: [], total: 0 },
        designers: { all: [], displayed: [], total: 0 },
        articles: { all: [], displayed: [], total: 0 },
      },
      view: 'products',
      isLoading: false,
      itemsPerPage: {
        products: 24,
        brands: 400,  // Updated to 72 for brands
        designers: 72,
        articles: 12,
      },
    };
  },


      computed: {
        productsCount() {
          return this.results.products.total
        },
        brandsCount() {
          return this.results.brands.total
        },
        designersCount() {
          return this.results.designers.total
        },
        articlesCount() {
          return this.results.articles.total
        },
        totalCount() {
          return this.productsCount + this.brandsCount + this.designersCount + this.articlesCount
        },
        showLoadMore() {
          return this.results[this.view].displayed.length < this.results[this.view].all.length
        },
        paginatedProducts() {
          return this.results.products.displayed
        },
        paginatedBrands() {
          return this.results.brands.displayed
        },
        paginatedDesigners() {
          return this.results.designers.displayed
        },
        paginatedArticles() {
          return this.results.articles.displayed
        },
        totalQuantityArticles() {
          return this.results.articles.all.length
        },
        shownQuantityArticles() {
          return this.results.articles.displayed.length
        },
        isLastPage() {
          return this.results[this.view].displayed.length >= this.results[this.view].all.length
        },
        getIsLoading() {
          return this.isLoading
        }
      },

      watch: {
        query: {
          immediate: true,
          handler() {
            this.search()
          },
        },
      },

      methods: {
        switchView(view) {
          this.view = view
          this.resetDisplayedResults()
        },

        resetDisplayedResults() {
    const perPage = this.itemsPerPage[this.view] || 24;
    this.results[this.view].displayed = this.results[this.view].all.slice(0, perPage);
  },

  loadMore() {
    const perPage = this.itemsPerPage[this.view] || 24;
    const currentLength = this.results[this.view].displayed.length;
    const nextBatch = this.results[this.view].all.slice(currentLength, currentLength + perPage);

    this.results[this.view].displayed = [...this.results[this.view].displayed, ...nextBatch];
  },


        nextPage() {
          this.loadMore()
        },

        search() {
          if (!this.query) return this.resetResults()

          const appId = process.env.VUE_APP_ALGOLIA_APPLICATION_ID
          const apiKey = process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY
          const productsIndex = process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX
          const brandsIndex = process.env.VUE_APP_ALGOLIA_BRANDS_INDEX
          const designersIndex = process.env.VUE_APP_ALGOLIA_DESIGNERS_INDEX
          const articlesIndex = process.env.VUE_APP_ALGOLIA_ARTICLES_INDEX

          const queries = [
            { indexName: productsIndex, query: this.query, params: { hitsPerPage: 10000 } },
            { indexName: brandsIndex, query: this.query, params: { hitsPerPage: 10000 } },
            { indexName: designersIndex, query: this.query, params: { hitsPerPage: 10000 } },
            { indexName: articlesIndex, query: this.query, params: { hitsPerPage: 10000 } },
          ]

          this.isLoading = true
          const client = algoliasearch(appId, apiKey)

          client.multipleQueries(queries).then(({ results }) => {
            this.results.products.all = results.find((r) => r.index === productsIndex)?.hits || []
            this.results.products.total = results.find((r) => r.index === productsIndex)?.nbHits || 0
            this.results.products.displayed = this.results.products.all.slice(0, this.itemsPerPage.products)

            this.results.brands.all = results.find((r) => r.index === brandsIndex)?.hits || []
            this.results.brands.total = results.find((r) => r.index === brandsIndex)?.nbHits || 0
            this.results.brands.displayed = this.results.brands.all.slice(0, this.itemsPerPage.brands)

            this.results.designers.all = results.find((r) => r.index === designersIndex)?.hits || []
            this.results.designers.total =
              results.find((r) => r.index === designersIndex)?.nbHits || 0
            this.results.designers.displayed = this.results.designers.all.slice(0, this.itemsPerPage.designers)

            this.results.articles.all = results.find((r) => r.index === articlesIndex)?.hits || []
            this.results.articles.total = results.find((r) => r.index === articlesIndex)?.nbHits || 0
            this.results.articles.displayed = this.results.articles.all.slice(0, this.itemsPerPage.articles)

            this.isLoading = false
          })
        },

        resetResults() {
          this.results = {
            products: { all: [], displayed: [], total: 0 },
            brands: { all: [], displayed: [], total: 0 },
            designers: { all: [], displayed: [], total: 0 },
            articles: { all: [], displayed: [], total: 0 },
          }
          this.isLoading = false
        },
      },
    }
</script>

<template>
  <main id="main-content">
    <article>
      <BasePageHeader :heading="query" :prefix="isCollection ? 'Collection' : 'Search'" />

      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 lg:block">
        <div class="border-gray-300 border-t md:border-b md:border-t-0 -mb-12 lg:-mb-0">
          <NewSearchViewToggle
            :view="view"
            :productsCount="productsCount"
            :brandsCount="brandsCount"
            :designersCount="designersCount"
            :articlesCount="articlesCount"
            @update:view="switchView"
          />
        </div>
      </div>

      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
        <div
          class="static flex flex-col md:flex-row lg:h-0 md:relative flex-wrap overflow-hidden mb-12"
          :class="filterBarOpen ? 'z-99' : ''"
        >
        </div>

        <div v-if="view === 'products'">
          <div
            class="relative py-6 md:col-span-2 lg:col-span-3"
            :class="`${moreWidth ? 'pl-0 ml-0 lg:ml-1 lg:pl-1' : 'md:pt-1 pl-0 ml-0 lg:pl-6'}`"
            :style="getIsLoading ? 'height: ' + (loaderHeight + 48) + 'px' : ''"
          >
            <transition name="fade">
              <div v-if="getIsLoading" class="z-10 loader" :style="loaderStyles">
                <BaseTileGridLoader @resize="setLoaderHeight" />
              </div>
            </transition>

            <transition name="fade">
              <div v-if="!getIsLoading">
                <section v-if="paginatedProducts.length">
                  <div
                    class="grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 lg:grid-cols-4"
                    role="feed"
                  >
                    <ProductTile
                      v-for="product in paginatedProducts"
                      :key="product.id"
                      v-bind="product"
                      :destination="destination"
                      :hide-price="hidePrice"
                    />
                  </div>

                  <!-- Pagination Buttons -->
                  <div class="mt-4 md:mt-6" v-if="!isLastPage">
                    <nav
                      aria-label="Product pagination"
                      class="flex flex-col justify-center items-center -mb-12 md:-mb-0 pb-0 pt-4 underline underline-2 hover:no-underline | md:flex-row || wrapper"
                      :class="`${isLastPage ? 'hasNoLoadMore' : ''}`"
                    >
                      <span
                        class="text-gray-500 || desktop invisible"
                        v-html="'Showing ' + shownQuantity + ' Products'"
                      />

                      <transition name="fade">
                        <BaseButton                          
                          :color="'white'"
                          :rounded="true"
                          class="mb-2 | md:mb-0 bg-transparent border-0 underline hover:no-underline"
                          :button-class="`font-bold tracking-150 uppercase border-0 underline hover:no-underline`"
                          :additional-btn-classes="`font-bold tracking-150 uppercase border-0 underline hover:no-underline border-none`"
                          @button-click="nextPage"
                        >
                          <span
                            class="border-solid border-0 border-b border-black hover:border-transparent tracking-150 font-unica77 font-bold"
                            style="border-bottom-width: 2px"
                          >
                            See More
                          </span>
                        </BaseButton>
                      </transition>

                      <span
                        class="text-gray-500 || desktop invisible"
                        aria-label="total products"
                        v-html="'Total ' + totalQuantity + ' Products'"
                      />

                      <span
                        class="block text-xs text-gray-500 || mobile invisible"
                        aria-current="true"
                        aria-label="current products"
                        v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
                      />
                    </nav>
                  </div>
                </section>

                <div v-if="isLoaded" class="p-4 text-center md:p-6">
                  <h3 class="mb-2 font-bold text-lg">No Products Found</h3>
                  <p>Please try again by widening your search and filter settings.</p>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <div v-if="view === 'brands'">
          <div
            class="relative py-4"
            :style="getIsLoading ? 'height: ' + (loaderHeight + 48) + 'px' : ''"
          >
            <transition name="fade">
              <div v-if="getIsLoading" class="absolute z-10 || loader" :style="loaderStyles">
                <BaseTileGridLoader @resize="setLoaderHeight" />
              </div>
            </transition>

            <transition name="fade">
              <div v-if="!getIsLoading">
                <section v-if="paginatedBrands.length">
                  <div
                    class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-5"
                    role="feed"
                  >
                    <BrandTile v-for="brand in paginatedBrands" :key="brand.id" v-bind="brand" />
                  </div>
                  <nav
                    ref="pagination"
                    aria-label="Product pagination"
                    class="flex flex-col justify-center invisible items-center pt-8 pb-4 border-t border-gray-300 | md:flex-row md:justify-between || wrapper"
                  >
                    <span
                      class="text-gray-500 || desktop"
                      v-html="'Showing ' + shownQuantity + ' Brands'"
                    />

                    <span
                      class="text-gray-500 || desktop"
                      aria-label="total products"
                      v-html="'Total ' + totalQuantity + ' Brands'"
                    />

                    <span
                      class="block text-xs text-gray-500 || mobile"
                      aria-current="true"
                      aria-label="current products"
                      v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Brands'"
                    />
                  </nav>
                </section>

                <div v-else class="p-4 text-center | md:p-6">
                  <h3 class="mb-2 font-bold text-lg">No Brands Found</h3>
                  <p>Please try again by widening your search and filter settings.</p>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <div v-if="view === 'designers'">
          <div
            class="relative py-4"
            :style="getIsLoading ? 'height: ' + (loaderHeight + 48) + 'px' : ''"
          >
            <transition name="fade">
              <div v-if="getIsLoading" class="absolute z-10 || loader" :style="loaderStyles">
                <BaseTileGridLoader @resize="setLoaderHeight" />
              </div>
            </transition>

            <transition name="fade">
              <div v-if="!getIsLoading">
                <section v-if="paginatedDesigners.length">
                  <div
                    class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-5"
                    role="feed"
                  >
                    <DesignerTile v-for="item in paginatedDesigners" :key="item.id" v-bind="item" />
                  </div>
                  <nav
                    aria-label="Product pagination"
                    class="flex flex-col justify-center items-center underline underline-2 hover:no-underline pt-0 pb-4 | md:flex-row || wrapper mt-4 | md:mt-6"
                  >
                    <span
                      class="text-gray-500 || desktop invisible"
                      v-html="'Showing ' + shownQuantity + ' Designers'"
                    />

                    <transition name="fade">
                      <BaseButton
                        v-if="!isLastPage"
                        :color="'gray'"
                        :rounded="true"
                        class="mb-2 | md:mb-0"
                        @button-click="nextPage"
                      >
                        Load More
                      </BaseButton>
                    </transition>

                    <span
                      class="text-gray-500 || desktop invisible"
                      aria-label="total products"
                      v-html="'Total ' + totalQuantity + ' Designers'"
                    />

                    <span
                      class="block text-xs text-gray-500 || mobile invisible"
                      aria-current="true"
                      aria-label="current products"
                      v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Designers'"
                    />
                  </nav>
                </section>

                <div v-else class="p-4 text-center | md:p-6">
                  <h3 class="mb-2 font-bold text-lg">No Designers Found</h3>
                  <p>Please try again by widening your search and filter settings.</p>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <div v-if="view === 'articles'">
          <div
            class="relative p-4 | md:p-6"
            :style="getIsLoading ? 'height: ' + (loaderHeight + 48) + 'px' : ''"
          >
            <transition name="fade">
              <div v-if="getIsLoading" class="absolute z-10 || loader" :style="loaderStyles">
                <BaseTileGridLoader @resize="setLoaderHeight" />
              </div>
            </transition>

            <transition name="fade">
              <div v-if="!getIsLoading">
                <section v-if="paginatedArticles.length">
                  <div
                    class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-6"
                    role="feed"
                  >
                    <ArticleTile v-for="item in paginatedArticles" :key="item.id" v-bind="item" />
                  </div>
                  <nav
                    aria-label="Inspiration pagination"
                    class="flex flex-col justify-center items-center pt-8 pb-4 border-t border-gray-300 | md:flex-row md:justify-between || wrapper mt-4 | md:mt-6"
                  >
                    <span
                      class="text-gray-500 || desktop"
                      v-html="'Showing ' + shownQuantityArticles + ' Inspirations'"
                    />

                    <transition name="fade">
                      <BaseButton
                        v-if="!isLastPage"
                        :color="'gray'"
                        :rounded="true"
                        class="mb-2 | md:mb-0"
                        @button-click="nextPage"
                      >
                        Load More
                      </BaseButton>
                    </transition>

                    <span
                      class="text-gray-500 || desktop"
                      aria-label="total products"
                      v-html="'Total ' + totalQuantityArticles + ' Inspirations'"
                    />

                    <span
                      class="block text-xs text-gray-500 || mobile"
                      aria-current="true"
                      aria-label="current products"
                      v-html="
                        'Showing ' +
                        shownQuantityArticles +
                        ' of ' +
                        totalQuantityArticles +
                        ' Inspirations'
                      "
                    />
                  </nav>
                </section>

                <div v-else class="p-4 text-center | md:p-6">
                  <h3 class="mb-2 font-bold text-lg">No Inspirations Found</h3>
                  <p>Please try again by widening your search and filter settings.</p>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </article>
  </main>
</template>
<style scoped lang="postcss">
.appendFilters {
    position: relative;
    top: 45px;
    z-index: 99;
    width: calc(100% - 300px);
    @media(max-width:1024px) {
      width: 100%;
    }
  }
  .filterActionBtn {
    position: absolute;
    top: 75px;
    cursor: pointer;
    left: -14px;
    z-index: 999;
    transform: rotate(-90deg);
    span {
      color:#000;
      font:500 14px/14px 'Inter';
    }
  }
  .transition--all {
    transition: all 0.25s ease-in-out;
  }
  .filterButtons {
    margin-right: 20px;
    display: flex;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 10px;
        span {
          display: inline-flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          padding: 0 37px 0 15px;
          height: 26px;
          color: #000;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          border: 1px solid #000;
          svg {
            position: absolute;
            right: 18px;
            top:50%;
            transform: translateY(-50%);
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
   .loader {
    top: 16px;
    right: 16px;
    left: 16px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .loader {
      top: 24px;
      right: 24px;
      left: 24px;
    }
  }
  .wrapper {
    min-height: 123px;
  }

  .desktop {
    @apply hidden;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .wrapper {
      min-height: 97px;
    }

    .mobile {
      @apply hidden;
    }

    .desktop {
      @apply inline-block;
    }
  }
</style>