import { render, staticRenderFns } from "./ProductSpecs.vue?vue&type=template&id=cdb95fea&scoped=true&"
import script from "./ProductSpecs.vue?vue&type=script&lang=js&"
export * from "./ProductSpecs.vue?vue&type=script&lang=js&"
import style0 from "./ProductSpecs.vue?vue&type=style&index=0&id=cdb95fea&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdb95fea",
  null
  
)

export default component.exports