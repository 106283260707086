import api from '@/api'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    hasLoaded: false,
    isSaving: false,
    isRemoving: false,
    itemsGroupedByListHandle: {},
  },

  mutations: {
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setHasLoaded(state, value) {
      state.hasLoaded = value
    },
    setIsSaving(state, value) {
      state.isSaving = value
    },
    setIsRemoving(state, value) {
      state.isRemoving = value
    },
    setItemsGroupedByListHandle(state, value) {
      state.itemsGroupedByListHandle = value
    },
  },

  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
    getHasLoaded(state) {
      return state.hasLoaded
    },
    getIsSaving(state) {
      return state.isSaving
    },
    getIsRemoving(state) {
      return state.isRemoving
    },
    getItemsGroupedByListHandle(state) {
      return state.itemsGroupedByListHandle
    },
    getListHandles(state) {
      return Object.keys(state.itemsGroupedByListHandle)
    },
    getSavedProducts(state) {
      return state.itemsGroupedByListHandle.savedProducts ?? []
    },
  },

  actions: {
    loadItems(context) {
      context.commit('setIsLoading', true)
      return api.post('hauteliving/site/get-list-items').then((response) => {
        if (response.status === 200) {
          context.commit('setItemsGroupedByListHandle', response.data)
        }
        context.commit('setIsLoading', false)
        context.commit('setHasLoaded', true)
      })
    },

    addItem(context, { variantId, options = {}, listHandle = 'savedProducts' }) {
      context.commit('setIsSaving', true)
      return api
        .post('wishlist/items/add', {
          elementId: variantId,
          options,
          listTypeHandle: listHandle,
        })
        .then(async (response) => {
          if (response.status === 200) {
            await context.dispatch('loadItems')
            context.commit('setIsSaving', false)
          } else {
            // eslint-disable-next-line no-console
            console.error(response)
          }
        })
    },

    removeItem(context, { variantId, options = {}, listHandle = 'savedProducts' }) {
      context.commit('setIsRemoving', true)
      return api
        .post('wishlist/items/remove', {
          elementId: variantId,
          // options,
          listTypeHandle: listHandle,
        })
        .then(async (response) => {
          if (response.status === 200) {
            await context.dispatch('loadItems')
            context.commit('setIsRemoving', false)
          } else {
            // eslint-disable-next-line no-console
            console.error(response)
          }
        })
    },
  },
}
